import React, { useEffect } from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import Link from 'next/link';
import { Caption, CaptionStyles, S3Styles } from '../Typography';
import config from '../../../config.json';

const { collections } = config;

const SidebarWrapper = styled.div<{ show?: boolean }>`
  width: 200px;
  ${({ show }) => !show && `opacity: 0; margin-left: -200px; visibility: hidden;`};
  position: -webkit-sticky;
  position: sticky;
  top: ${({ theme }) => theme.bannerHeight + theme.headerHeight + 32}px;
  align-self: flex-start;
  white-space: nowrap;
  transition: all 0.25s ease-in-out;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    position: relative;
    top: 0;
    width: 100%;
    opacity: 1;
    margin-left: 0;
    visibility: visible;
  }
`;

const SidebarSection = styled.div<{ mobile?: boolean }>`
  margin-bottom: 32px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding-left: 16px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
    display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  }
`;

const SidebarHeading = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  text-transform: uppercase;
  margin-bottom: 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const SidebarItem = styled(Caption)<{
  active?: boolean;
  last?: boolean;
  isHololive?: boolean;
}>`
  ${CaptionStyles}
  display:block;
  margin: 8px 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  ${({ active }) => active && S3Styles}
  ${({ isHololive }) => isHololive && `color: #1B93BC;`}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-right: ${({ last }) => (last ? 16 : 24)}px;
  }
`;

const NewCollectionText = styled.span<{ active?: boolean }>`
  ${CaptionStyles}
  color: ${({ theme }) => theme.colors.greenNewTag};
  display: inline;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`;

type SidebarProps = {
  handle?: string;
  show?: boolean;
  sidebarItems: SidebarItemsType[];
};

const Sidebar: React.FC<SidebarProps> = ({ handle, show, sidebarItems }: SidebarProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, []);

  return (
    <SidebarWrapper show={show}>
      {sidebarItems.map((sidebarItem) => (
        <SidebarSection data-gtm-name="filter-name" key={sidebarItem.title} mobile={sidebarItem.mobile}>
          <SidebarHeading>{sidebarItem.title}</SidebarHeading>
          {sidebarItem.links.map((link, index) => (
            <Link key={link.title} href={`/shop/${link.handle}`} passHref>
              {link.handle === handle ? (
                <SidebarItem
                  active
                  last={sidebarItem.links.length - 1 === index}
                  ref={ref}
                  isHololive={link.handle === collections.hololive}
                >
                  {link.isNew && (
                    <NewCollectionText data-gtm-name="span-new" active>
                      New!{' '}
                    </NewCollectionText>
                  )}
                  {link.title}
                </SidebarItem>
              ) : (
                <SidebarItem
                  last={sidebarItem.links.length - 1 === index}
                  isHololive={link.handle === collections.hololive}
                >
                  {link.isNew && <NewCollectionText>New! </NewCollectionText>}
                  {link.title}
                </SidebarItem>
              )}
            </Link>
          ))}
        </SidebarSection>
      ))}
    </SidebarWrapper>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NextPage, GetServerSideProps } from 'next';
import StyledLink from '@makeship/core/lib/components/Link';
import Image from 'next/image';
import { scroller } from 'react-scroll';

import posthog from 'posthog-js';
import Cookies from 'js-cookie';
import Container from '../../components/Container';
import Head from '../../components/Head';
import { H2, H4Styles, P1, P2, P2Styles } from '../../components/Typography';
import ProductsDisplay from '../../components/ProductsDisplay';
import Sidebar from '../../components/Sidebar';

import { getCollectionByHandle } from '../../api/collections';
import { getSideBarItems } from '../../api/storefront/product';

import {
  getEndingSoonProducts,
  getNewArrivalProducts,
  getNextPage,
  getCollectionProducts,
  filterProducts,
  isSpecialCollection,
  filterSuccessfulProducts,
} from '../../utils/collections';
import config from '../../../config.json';
import { defaultSidebarItems } from '../../utils/sidebar';
import { getCollectionBanner } from '../../components/CustomCollectionBanners';
import { CookieConsent } from '../../types/cookies';
import { getPropsForPageLoad } from '../../utils/pageLoad';
import { useNavTheme } from '../../context/theme';

const { collections, pagination } = config;

export const ShopPageWrapper = styled(Container)`
  padding: 48px 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding: 24px 16px;
  }
`;

export const ShopPageContents = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: column;
    margin-top: 16px;
  }
`;

export const TitleTextNew = styled(H2)`
  color: ${({ theme }) => theme.colors.greenNewTag};
  display: inline;
`;

const ShopPageH2 = styled(H2)`
  text-transform: uppercase;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    ${H4Styles}
  }
`;

const PetitionCollectionH2 = styled(H2)`
  display: inline;
  ${ShopPageH2}
`;

const ShopPageP1 = styled(P1)`
  width: 55%;
  margin-top: 8px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    width: 90%;
    margin-bottom: 16px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    ${P2Styles}
  }
`;

const PetitionsShopPageP1 = styled(P1)`
  margin-bottom: 8px;
  ${ShopPageP1}
`;

const PetitionsFAQLink = styled.a``;

const PetitionsFAQLinkText = styled(StyledLink.Primary)``;

const HideFiltersRow = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const HideFilters = styled.div`
  display: flex;
  cursor: pointer;
`;

const HideFiltersText = styled(P2)`
  margin-right: 8px;
  &:hover {
    text-decoration: underline;
  }
`;

const defaultSubheading = `Fueled by the Community, you help bring the products designed by your favorite Creators to life, by funding the
Campaign within the 21 days.`;

type ShopPageProps = {
  title: string;
  handle: string;
  products: Shopify.ProductEdge[];
  collection?: Shopify.Collection;
  sidebarItems: SidebarItemsType[];
  referringURL?: string;
};

const ShopPage: NextPage<ShopPageProps> = ({
  title,
  handle,
  products,
  collection,
  sidebarItems,
  referringURL,
}: ShopPageProps) => {
  const [pageProducts, setPageProducts] = useState<Shopify.ProductEdge[]>([]);
  const [initialPageProductsLoaded, setInitialPageProductsLoaded] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const showEvergreenInCarousel = false;
  const { handleNavThemeChange } = useNavTheme();

  useEffect(() => {
    if ((window as any).drift && (window as any).drift.api) {
      (window as any).drift.api.widget.hide();
    }
    return () => {
      if ((window as any).drift && (window as any).drift.api) {
        (window as any).drift.api.widget.show();
      }
    };
  }, []);

  useEffect(() => {
    // Used for saved cursor load and scrolling
    const previousProducts = sessionStorage.getItem('products');
    const visitedProductCard = sessionStorage.getItem('visitedProductCard');

    if (
      previousProducts &&
      visitedProductCard &&
      referringURL &&
      collection &&
      referringURL.includes(collection?.handle) &&
      // no collection save behaviour for petition collection
      !referringURL.includes('/shop/petitions')
    ) {
      const productList = JSON.parse(previousProducts);
      setPageProducts(productList);
      setCanLoadMore(collection.products.edges.length === pagination.products);
      setInitialPageProductsLoaded(true);
    } else {
      setPageProducts(products);
      setCanLoadMore((collection?.products.edges.length || products.length) === pagination.products);
    }
  }, [products]);

  // Scroll to previously visited card logic
  useEffect(() => {
    const visitedProductCard = sessionStorage.getItem('visitedProductCard');
    if (visitedProductCard) {
      scroller.scrollTo(visitedProductCard, {
        duration: 800,
        smooth: 'easeInOutQuad',
        offset: -425,
      });
    }

    // After scrolling, remove product list because it has already been used (ie: it's a stale list)
    sessionStorage.removeItem('products');
  }, [initialPageProductsLoaded]);

  // Save page products into session storage as they're loaded
  useEffect(
    () => () => {
      if (pageProducts[pageProducts.length - 1]?.cursor) {
        const cookieConsentAccepted = Cookies.get(CookieConsent.Functional) === 'true';
        if (!cookieConsentAccepted) return;
        sessionStorage.setItem('collectionHandle', collection?.handle || '');
        sessionStorage.setItem('products', JSON.stringify(pageProducts) || '');
      }
    },
    [pageProducts],
  );

  const loadMore = () => {
    if (!collection) return;
    setLoading(true);

    getNextPage(collection?.handle, pageProducts[pageProducts.length - 1].cursor || undefined)
      .then((newProducts) => {
        if (!newProducts) return;
        setPageProducts(pageProducts.concat(newProducts));
        if (newProducts.length < pagination.products) {
          setCanLoadMore(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const renderPetitionHeaderInfo = () => (
    <>
      <TitleTextNew>NEW! </TitleTextNew>
      <PetitionCollectionH2 data-testid="collection-title">PETITIONS</PetitionCollectionH2>
      <PetitionsShopPageP1 data-testid="collection-subheader">
        A place where communities can bring creator ideas to life! 🚀
      </PetitionsShopPageP1>
      <PetitionsFAQLink
        href="https://support.makeship.com/hc/en-us/articles/29429491310477-What-is-the-difference-between-a-petition-and-a-campaign"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => posthog.capture('petitionCollection_FAQ_link_click')}
      >
        <PetitionsFAQLinkText>Learn more about Petitions</PetitionsFAQLinkText>
      </PetitionsFAQLink>
    </>
  );

  return (
    <>
      <Head
        title={title}
        description={collection?.description || defaultSubheading}
        url={`/shop/${handle}`}
        image={collection?.image?.transformedSrc}
      />
      {getCollectionBanner(collection?.handle)}
      <ShopPageWrapper>
        {!isSpecialCollection(collection?.handle) && (
          <>
            <ShopPageH2 data-testid="collection-title">{title}</ShopPageH2>
            <ShopPageP1 data-testid="collection-subheader">{collection?.description || defaultSubheading}</ShopPageP1>
          </>
        )}
        {collection?.handle === 'petitions' && renderPetitionHeaderInfo()}
        <HideFiltersRow>
          <HideFilters onClick={() => setShowSidebar(!showSidebar)}>
            <HideFiltersText data-gtm-name="show-hide">{showSidebar ? 'Hide' : 'Show'} Filters</HideFiltersText>
            <Image
              data-gtm-name="filter-span"
              src="/assets/icons/filters-icon.svg"
              width={16}
              height={16}
              quality={90}
            />
          </HideFilters>
        </HideFiltersRow>
        <ShopPageContents>
          <Sidebar handle={handle} show={showSidebar} sidebarItems={sidebarItems} />
          <ProductsDisplay
            products={
              [(collections.liveCampaigns, collections.gitd2024)].includes(handle)
                ? filterProducts(pageProducts, false, false, true)
                : getCollectionProducts(
                    pageProducts,
                    title === 'Ending Soon',
                    showEvergreenInCarousel,
                    title === 'Petitions',
                  )
            }
            loadMore={loadMore}
            canLoadMore={canLoadMore}
            loading={loading}
            isOnVinylCollectionPage={collection?.handle === 'vinyls'}
            titleForTracking={title}
          />
        </ShopPageContents>
      </ShopPageWrapper>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<ShopPageProps> = async (ctx) => {
  const { params } = ctx;
  const handle = params ? (params.collection as string) : '';
  const referringURL = ctx.req.headers.referer || '';
  const propsForPageLoad = await getPropsForPageLoad(ctx);

  const [sidebarItemsFiltered] = await Promise.all([
    getSideBarItems()
      .then((res) => res.data)
      .catch(() => defaultSidebarItems),
  ]);

  switch (handle) {
    case 'ending-soon': {
      const products = await getEndingSoonProducts(collections.liveCampaigns);

      return {
        props: {
          title: 'Ending Soon',
          handle,
          products,
          sidebarItems: sidebarItemsFiltered,
          referringURL,
          ...propsForPageLoad,
        },
      };
    }
    case 'new': {
      const products = await getNewArrivalProducts(collections.liveCampaigns);

      return {
        props: {
          title: 'New Arrivals',
          handle,
          products,
          sidebarItems: sidebarItemsFiltered,
          referringURL,
          ...propsForPageLoad,
        },
      };
    }
    default: {
      const numberOfProducts = pagination.products;

      const collection = await getCollectionByHandle(handle, undefined, 1, numberOfProducts);
      if (!collection) {
        return {
          notFound: true,
        };
      }

      const productEdges = filterSuccessfulProducts(collection.products.edges);

      if (productEdges.length === 0) {
        return {
          redirect: {
            destination: `/shop/featured`,
            permanent: false,
          },
        };
      }

      return {
        props: {
          title: collection.title,
          handle,
          products: productEdges,
          collection,
          sidebarItems: sidebarItemsFiltered,
          referringURL,
          ...propsForPageLoad,
        },
      };
    }
  }
};

export default ShopPage;

import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import { H5 } from '../Typography';

import config from '../../../config.json';

const { collections } = config;

const MastheadRunescapeWrapper = styled.div`
  background-image: url('/assets/collections/runescape/runescape-background.png');
  background-position: center;
  background-size: cover;
  height: 210px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: column;
  }
`;

const RunescapeLogoWrapper1 = styled.div`
  position: relative;
  width: 264px;
  height: 114px;
`;

const RunescapeLogoWrapper2 = styled.div`
  position: relative;
  width: 251px;
  height: 141px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    width: 251px;
    height: 100px;
  }
`;

const MastheadWebtoonWrapper = styled.div`
  background-image: url('/assets/collections/webtoon_1920_masthead.png');
  background-position: center;
  height: 178px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 166px;
  }
`;

const WebtoonLogoWrapper = styled.div`
  position: relative;
  height: 165px;
  width: 173px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 130px;
    width: 136px;
  }
`;

const MastheadNinjaKiwiWrapper = styled.div`
  background-image: url('/assets/collections/BTD6_banner_1920x210.png');
  background-position: center;
  background-size: cover;
  height: 178px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 45px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 166px;
    padding: 33px 15px;
  }
`;

const MastheadKingsIsleWrapper = styled.div`
  background-image: url('/assets/collections/wizard101-banner.png');
  background-position: center;
  background-size: cover;
  height: 178px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 45px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 166px;
    padding: 33px 15px;
  }
`;

const VinylsBannerWrapper = styled.div`
  background-image: url('/assets/vinyls/VF-Collection-Banner.png');
  background-position: center;
  background-size: cover;
  height: 178px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 166px;
    padding: 32px 16px;
  }
`;

const NinjaKiwiLogoWrapper = styled.div`
  position: relative;
  height: 143px;
  width: 249px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 116px;
    width: 220px;
  }
`;

const VinylsLogoWrapper = styled.div`
  position: relative;
  height: 80px;
  width: 356px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 64px;
    width: 276px;
  }
`;

const VinylsBannerText = styled(H5)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral1};
  margin: 8px 0px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 18px;
    margin: 0px;
  }
`;

const VinylsLogo = styled(Image)`
  height: 80px;
`;

const MastheadGuiltyGearWrapper = styled.div`
  background-image: url('/assets/collections/GGS_Collection-Banner_3.png');
  background-position: center;
  background-size: cover;
  height: 210px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 45px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 166px;
    padding: 33px 15px;
  }
`;

const GuiltyGearLogo = styled.div`
  position: relative;
  height: 106px;
  width: 300px;
`;

const MastheadHololiveWrapper = styled.div`
  background-image: url('/assets/collections/hololive_banner.png');
  background-position: center;
  background-size: cover;
  height: 210px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 45px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    height: 166px;
    padding: 33px 15px;
  }
`;

const HololiveLogo = styled.div`
  position: relative;
  height: 86px;
  width: 300px;
`;

export const getCollectionBanner = (handle: string | undefined) => {
  switch (handle) {
    case collections.runescape:
      return (
        <MastheadRunescapeWrapper>
          <RunescapeLogoWrapper1>
            <Image
              src="/assets/collections/runescape/runescape-logo.png"
              layout="fill"
              objectFit="contain"
              quality={100}
            />
          </RunescapeLogoWrapper1>
          <RunescapeLogoWrapper2>
            <Image
              src="/assets/collections/runescape/runescape-logo-2.png"
              layout="fill"
              objectFit="contain"
              quality={100}
            />
          </RunescapeLogoWrapper2>
        </MastheadRunescapeWrapper>
      );
    case collections.webtoon:
      return (
        <MastheadWebtoonWrapper>
          <WebtoonLogoWrapper>
            <Image src="/assets/collections/webtoon-logo-revised.png" layout="fill" objectFit="contain" quality={100} />
          </WebtoonLogoWrapper>
        </MastheadWebtoonWrapper>
      );
    case collections.ninjakiwi:
      return (
        <MastheadNinjaKiwiWrapper>
          <NinjaKiwiLogoWrapper>
            <Image src="/assets/collections/BTD6_logo.png" layout="fill" objectFit="contain" quality={100} />
          </NinjaKiwiLogoWrapper>
        </MastheadNinjaKiwiWrapper>
      );
    case collections.vinyls:
      return (
        <VinylsBannerWrapper>
          <VinylsBannerText>New!</VinylsBannerText>
          <VinylsLogoWrapper>
            <VinylsLogo
              src="/assets/vinyls/VF_Logo-Horiz-2x.png"
              layout="responsive"
              height="52px"
              width="356px"
              quality={100}
              alt="Vinyl Figures"
            />
          </VinylsLogoWrapper>
          <VinylsBannerText>Collection</VinylsBannerText>
        </VinylsBannerWrapper>
      );
    case collections.kingsisleentertainment:
      return <MastheadKingsIsleWrapper />;
    case collections.guiltygearstrive:
      return (
        <MastheadGuiltyGearWrapper>
          <GuiltyGearLogo>
            <Image src="/assets/collections/GGST_logo.png" layout="fill" objectFit="contain" quality={100} />
          </GuiltyGearLogo>
        </MastheadGuiltyGearWrapper>
      );
    case collections.hololive:
      return (
        <MastheadHololiveWrapper>
          <HololiveLogo>
            <Image
              src="/assets/collections/hololive_logo.png"
              layout="fill"
              objectFit="contain"
              alt="Hololive Logo"
              quality={100}
            />
          </HololiveLogo>
        </MastheadHololiveWrapper>
      );
    default:
      return <></>;
  }
};
